import classnames from 'classnames';

import { Typography } from '@pumpkincare/shared/ui';

import ClaimsHowToFile from '../claims-how-to-file';
import DirectDepositInfo from '../direct-deposit-info';
import TrackClaims from '../track-claims';

import styles from './account-claims-wrapper.css';

function AccountClaimsWrapper() {
  const headerCn = classnames(Typography.h1, styles.header);

  return (
    <div>
      <div className={styles.fileClaimContainer}>
        <h1 className={headerCn}>My Claims</h1>

        <ClaimsHowToFile />
      </div>

      <TrackClaims />

      <div className={styles.directDepositContainer}>
        <DirectDepositInfo />
      </div>
    </div>
  );
}

export default AccountClaimsWrapper;
