import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatPetBirthdate, useBooleanInput } from '@pumpkincare/shared';
import { ButtonStyles, LegalBody } from '@pumpkincare/shared/ui';

import PetMedicalHistoryUpload from '../pet-medical-history-upload';

import styles from './pet-card.module.css';

function PetCard(props) {
  const { className, petCompleteInfo, pet, vet } = props;

  const [isModalOpen, toggleIsModalOpen] = useBooleanInput(false);

  const date = petCompleteInfo.birth_date
    ? formatPetBirthdate(petCompleteInfo.birth_date)
    : null;

  return (
    <div
      className={classNames(styles.root, className)}
      data-testid={`${pet.name}-card`}
    >
      <div className={styles.header}>
        <div className={styles.nameContainer}>
          <h4 className={styles.name} data-testid='pet-name-label'>
            {pet.name}
          </h4>
        </div>
      </div>

      <LegalBody className={styles.petInfo}>
        {petCompleteInfo.birth_date ? (
          <div className={styles.imgDiv}>
            <img
              className={styles.img}
              src='/assets/images/iconImages/cake.svg'
              alt='birthday cake'
            />
            {` ${date}`}
          </div>
        ) : null}

        {petCompleteInfo.weight ? (
          <div className={styles.imgDiv}>
            <img
              className={styles.img}
              src='/assets/images/iconImages/weight.svg'
              alt='gym weight'
            />
            {`${petCompleteInfo.weight} lbs`}
          </div>
        ) : null}
      </LegalBody>

      <div className={styles.separator} />

      {vet ? (
        <div>
          <LegalBody
            className={styles.section}
            data-testid='vet-clinic-label'
          >{`Vet Clinic: ${vet.name}`}</LegalBody>
        </div>
      ) : null}

      <div className={styles.medicalRecords}>
        <button
          onClick={toggleIsModalOpen}
          className={classNames(ButtonStyles.baseButton, styles.button)}
          data-testid='add-records-button'
        >
          Add Medical Records
        </button>
      </div>

      {isModalOpen && (
        <PetMedicalHistoryUpload
          petName={pet.name}
          petId={pet.id}
          vetId={vet ? vet.id : null}
          onClose={toggleIsModalOpen}
        />
      )}
    </div>
  );
}

PetCard.defaultProps = {
  className: '',
};

PetCard.propTypes = {
  pet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,

  vet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }),

  petCompleteInfo: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default PetCard;
